import { XF } from "./js/XF";

const noticeContainer = document.querySelector(
  ".newsletter-opt-in-notice-container"
);
if (!noticeContainer)
  throw new Error("Could not find newsletter opt in container");

const dismissAnchor = document.querySelector(".js-newsletterOptInDismiss");

dismissAnchor?.addEventListener("click", (e) => {
  e.preventDefault();
  noticeContainer.remove();
  XF.ajax("post", "/account/dismissNewsletterOptIn", undefined, undefined, {
    skipDefaultSuccess: true,
  });
});

const optinBtn = document.querySelector<HTMLButtonElement>(
  ".js-newsletterOptIn"
);

optinBtn?.addEventListener("click", (e) => {
  e.preventDefault();
  XF.ajax(
    "post",
    "/account/newsletterOptIn",
    undefined,
    onNewsletterOptInCallback,
    undefined
  );
});

const onNewsletterOptInCallback = () => {
  optinBtn!.textContent = "Success!";
  optinBtn!.disabled = true;
};
